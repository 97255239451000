<template>
  <layout-view>
    <template v-slot:content="{height}">
      <graphql-table-view
        title="Список"
        query="
          query Query(
            $page: Int,
            $onPage: Int,
            $sortBy: String,
            $sortDesc: Boolean,
            $sumFromDate: String,
            $sumToDate: String
          ){
            Insur(
              page: $page,
              onPage: $onPage,
              sortBy: $sortBy,
              sortDesc: $sortDesc
              sumFromDate: $sumFromDate
              sumToDate: $sumToDate
            ){
              id
              name
              fullName
              oms
              dms
              contract
              date
              end
              contractNumber
              comment
              sumPayOms
              sumPayDms
              sumPayContract
              sumPayInsur
              sumAccountInInsur
              sumAccountOutInsur
            }
          }
        "
        :queryVars="{sumFromDate:sumFromDate, sumToDate:sumToDate}"
        queryMainEntity="Insur"
        :headers="[
          { text: 'ID', value: 'id', class:'text-no-wrap'},
          { text: 'ОМС', value: 'oms', class:'text-no-wrap', sortable: false},
          { text: 'ДМС', value: 'dms', class:'text-no-wrap', sortable: false},
          { text: 'Контракт', value: 'contract', class:'text-no-wrap', sortable: false},
          { text: 'Название', value: 'name', class:'text-no-wrap', sortable: false },
          { text: 'Полное название', value: 'fullName', class:'text-no-wrap', sortable: false},
          { text: 'Дата договора', value: 'date', class:'text-no-wrap', sortable: false},
          { text: 'Дата окончания', value: 'end', class:'text-no-wrap', sortable: false},
          { text: 'Договор', value: 'contractNumber', class:'text-no-wrap', sortable: false},
          { text: 'ОМС + ДМС + Контракт + Организация = Итого', value: 'sumPay', class:'text-no-wrap', sortable: false},
          { text: 'Пополнение - Снятие = Итого', value: 'sumInsur', class:'text-no-wrap', sortable: false},
          { text: 'Комментарий', value: 'comment', class:'text-no-wrap', width: '100%', sortable: false},
        ]"
        tableClass="insurs"
        :height="height"
        ref="table"
      >
        <template v-slot:actions>
          <v-row class="align-center">
            <v-col>
              <v-sheet class="d-inline-block py-3" width="200">
                <v-text-field
                    v-model="sumFromDate"
                    label="C"
                    :rules="formFieldRules.required"
                    outlined
                    dense
                    hide-details="auto"
                    v-mask="'##.##.####'"
                />
              </v-sheet>
            </v-col>
            <v-col>
              <v-sheet class="d-inline-block py-3" width="200">
                <v-text-field
                    v-model="sumToDate"
                    label="До, включительно"
                    :rules="formFieldRules.required"
                    outlined
                    dense
                    hide-details="auto"
                    v-mask="'##.##.####'"
                />
              </v-sheet>
            </v-col>
            <v-col>
              <v-btn small depressed @click="companyState.modalOpen({
                name:null,
                fullName:null,
                date:null,
                end:null,
                contractNumber:null,
                comment:null,
              }, '/api/insur/')">Создать</v-btn>
            </v-col>
          </v-row>
        </template>
        <template v-slot:item="{item}">
          <tr @click="companyState.modalOpen($tools.copy(item), '/api/insur/'+item.id+'/', true)">
            <td class="text-no-wrap">{{item.id}}</td>
            <td class="text-no-wrap">
              <v-icon small class="mt-n1" :color="item.oms ? 'success' : 'grey'">{{item.oms ? 'mdi-check-circle' : 'mdi-close-circle'}}</v-icon>
            </td>
            <td class="text-no-wrap">
              <v-icon small class="mt-n1" :color="item.dms ? 'success' : 'grey'">{{item.dms ? 'mdi-check-circle' : 'mdi-close-circle'}}</v-icon>
            </td>
            <td class="text-no-wrap">
              <v-icon small class="mt-n1" :color="item.contract ? 'success' : 'grey'">{{item.contract ? 'mdi-check-circle' : 'mdi-close-circle'}}</v-icon>
            </td>
            <td class="text-no-wrap">{{item.name}}</td>
            <td class="text-no-wrap">{{item.fullName}}</td>
            <td class="text-no-wrap">{{$tools.date(item.date)}}</td>
            <td class="text-no-wrap">{{$tools.date(item.end)}}</td>
            <td class="text-no-wrap">{{item.contractNumber}}</td>
            <td class="text-no-wrap">
              {{$tools.price(item.sumPayOms)}} +
              {{$tools.price(item.sumPayDms)}} +
              {{$tools.price(item.sumPayContract)}} +
              {{$tools.price(item.sumPayInsur)}} =
              {{$tools.price($tools.parseNumber(item.sumPayOms)+$tools.parseNumber(item.sumPayDms)+$tools.parseNumber(item.sumPayContract))}}
            </td>
            <td class="text-no-wrap">
              {{$tools.price(item.sumAccountInInsur)}} -
              {{$tools.price(item.sumAccountOutInsur)}} =
              {{$tools.price($tools.parseNumber(item.sumAccountInInsur)-$tools.parseNumber(item.sumAccountOutInsur))}}
            </td>
            <td class="text-no-wrap">{{item.comment}}</td>
          </tr>
        </template>
      </graphql-table-view>
    </template>
    <template v-slot:footer>
      <dialog-form-view
        title="Компания"
        :state="companyState"
        @success="$refs.table.load(true)"
        :canDelete="companyState.modalData2===true"
      >
        <template v-slot:content>
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="companyState.modalData.name" label="Название" :rules="formFieldRules.required" hide-details outlined dense/>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="companyState.modalData.fullName" label="Полное название" :rules="formFieldRules.required" hide-details outlined dense/>
            </v-col>
            <v-col cols="6">
              <date-view title="Дата договора" :date.sync="companyState.modalData.date" required/>
            </v-col>
            <v-col cols="6">
              <date-view title="Дата окончания" :date.sync="companyState.modalData.end" required/>
            </v-col>
            <v-col cols="4">
              <v-checkbox v-model="companyState.modalData.oms" label="ОМС" class="mt-0" dense hide-details />
            </v-col>
            <v-col cols="4">
              <v-checkbox v-model="companyState.modalData.dms" label="ДМС" class="mt-0" dense hide-details />
            </v-col>
            <v-col cols="4">
              <v-checkbox v-model="companyState.modalData.contract" label="Контракт" class="mt-0" dense hide-details />
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="companyState.modalData.contractNumber" label="Договор" :rules="formFieldRules.required" hide-details outlined dense/>
            </v-col>
            <v-col cols="12">
              <v-textarea v-model="companyState.modalData.comment" label="Комментарий" rows="2" hide-details auto-grow outlined dense/>
            </v-col>
          </v-row>
        </template>
      </dialog-form-view>
    </template>
  </layout-view>
</template>

<script>
import LayoutView from "@/components/LayoutView";
import GraphqlTableView from "@/components/GraphqlTableView";
import DialogFormView from "@/components/DialogFormView";
import State from "@/plugins/state";
import {FormFieldRules} from "@/plugins/formFieldRules";
import DateView from "@/components/DateView";
import moment from "moment/moment";
export default {
  components: {DateView, DialogFormView, GraphqlTableView, LayoutView},
  data: () => ({
    tabSelected:0,
    companyState:new State(),
    formFieldRules: FormFieldRules,
    sumFromDate: moment().subtract(1, "month").format('DD.MM.YYYY'),
    sumToDate: moment().format('DD.MM.YYYY'),
  }),
  watch: {
    sumFromDate(){
      this.$tools.throttle(()=>{
        this.$refs.table.load();
      });
    },
    sumToDate(){
      this.$tools.throttle(()=>{
        this.$refs.table.load();
      });
    },
  }
}
</script>

<style lang="scss">
.insurs tr{
  th, td {
    width: 100px !important;
    white-space: nowrap !important;
  }
  th:last-child, td:last-child {
    width: 100% !important;
  }
}
</style>


