<template>
  <v-form ref="form">
    <v-dialog
      v-model="state.isModalOpen"
      @input="state.modalClose()"
      @click:outside="state.modalClose()"
      :width="typeof big!=='undefined' ? 1280 : 425"
      scrollable
    >
      <v-card>
        <v-toolbar dense elevation="0" color="transparent" max-height="48px">
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer/>
          <slot name="barActions"/>
          <v-btn icon @click="state.modalClose()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider/>
        <div v-if="state.modalData!==null" :class="(typeof contentFullSize!=='undefined' ? '' : 'pa-4')+' overflow-y-auto body-2'">
          <slot name="content"/>
        </div>
        <v-divider/>
        <v-card-actions class="footer">
          <v-btn text @click="state.modalClose()">Отмена</v-btn>
          <v-spacer/>
          <v-btn v-if="canDelete" text @click="deleteState.modalOpen()">Удалить</v-btn>
          <v-btn text color="primary" :loading="state.isLoading" @click="save()">Сохранить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="deleteState.isModalOpen"
        @input="deleteState.modalClose()"
        @click:outside="deleteState.modalClose()"
        width="250"
        scrollable
    >
      <v-card>
        <v-toolbar dense elevation="0" color="transparent" max-height="48px">
          <v-toolbar-title>Удалить?</v-toolbar-title>
          <v-spacer/>
          <v-btn icon @click="deleteState.modalClose()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider/>
        <v-card-actions>
          <v-btn text @click="deleteState.modalClose()">Отмена</v-btn>
          <v-spacer/>
          <v-btn text @click="del()" :loading="deleteState.isLoading" color="primary">Удалить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import axios from "axios";
import State from "@/plugins/state";

export default {
  props: [
    'state',
    'title',
    'big',
    'contentFullSize',
    'canDelete'
  ],
  data: () => ({
    deleteState:new State(),
  }),
  watch: {
    open(){
      this.isOpen = this.open;
    },
  },
  methods:{
    del(){
      this.deleteState.stateLoading();
      axios.delete(this.state.modalData1)
          .then(() =>{
            this.deleteState.stateSuccess();
            this.deleteState.modalClose();
            this.state.stateSuccess();
            this.state.modalClose();
            this.$emit('success')
          })
          .catch(e =>{
            this.deleteState.stateError(e);
          });
    },
    save(){
      if (this.$refs.form.validate()){
        this.state.stateLoading();
        axios.post(this.state.modalData1, this.state.modalData)
          .then(() =>{
            this.state.stateSuccess();
            this.state.modalClose();
            this.$emit('success')
          })
          .catch(e =>{
            this.state.stateError(e);
          });
      } else {
        this.$store.commit('alertError', 'Не все обязательные поля заполнены');
      }
    }
  }
}
</script>


<style scoped lang="scss">
.dialogView{
  > .bar,
  > .header,
  > .footer{
    background: #F7F7F7 !important;
  }
}
</style>